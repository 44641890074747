@import "../../assets/sass/helper";


@include media_768(min) {
/*  .dsn-portfolio.port-classic:not(.data-influencer) .influencer-item:nth-of-type(2) {
    margin-top: calc(var(--dsn-row-item, 0px) + 80px);
  }*/

  .dsn-portfolio.port-classic.data-influencer .influencer-item:nth-of-type(4) {
    margin-top: calc(var(--dsn-row-item, 0px) + 80px);
  }

  .my-masonry-grid_column:nth-of-type(2) {
    margin-top: calc(var(--dsn-row-item, 0px) + 80px);
  }

  .my-masonry-grid_column:nth-of-type(4) {
    margin-top: calc(var(--dsn-row-item, 0px) + 80px);
  }

}
/*.my-masonry-grid_column:nth-of-type(2) {
  margin-top: calc(var(--dsn-row-item, 0px) + 80px);

}*/

.my-masonry-grid_column {
  .influencer-item{
    margin-top: 30px;
  }
}

@include media_991(max){
  .my-masonry-grid_column:nth-of-type(2) {
    margin-top: calc(var(--dsn-row-item, 0px) + 80px);
  }
}

/*@include media_991(max) {
  .my-masonry-grid_column {
    margin-top: 80px;
  }
}*/


.influencer-item {
  position: relative;
  overflow: hidden;

  .port-classic & .inner-img {
    height: 600px;
    @include media_768(max) {
        height: 640px;
    }
  }


  .blog-item {

    .box-img {
      position: relative;
      overflow: hidden;

      img {
        height: 640px;
        object-fit: cover;
        @include media_768(max) {
          height: 600px;
        }
      }
    }

    .box-content {
      position: relative;
      bottom: 45px;
      margin-left: 0;
      margin-bottom: -50px;
      padding: 40px;
      z-index: 1;

      .full-name {
        color: $body-color;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .title-name {
        color: $heading-color;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .social-info {
        gap: 3px;
        margin-top: 25px;
        justify-content: space-evenly;

        display: flex;
        flex-wrap: wrap;

        a {
          margin-bottom: 5px;
         border: 1px solid $border-color;
         // outline: solid 1px $border-color;
          padding: 12px;
          text-transform: lowercase;
          letter-spacing: 1px;
          overflow: hidden;
          white-space: nowrap;
          cursor: pointer;
          svg {
            margin-right: 8px;
          }
        }
      }
      .badge-container {
        display: inline-block;
        position: absolute;
        top: -50px;
        margin-right: 10px;
        right: calc(90px * var(--badge-index));
      }
    }
  }

  @include media_991(min) {
    .blog-item {
      .box-content {
        margin-left: 50px;
      }
    }
  }
}

.data-influencer{
  @include media_991(min) {
    .blog-item {
      .box-content {
        margin-left: 20px;
        .social-info{
          margin-inline: -10px;
        }
      }
    }
  }
}


.my-masonry-grid {
  display: flex;
  margin-left: -30px; // Odległość między kolumnami
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 30px; // Przestrzeń między kolumnami
  background-clip: padding-box;
}
