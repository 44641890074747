@import "../../../assets/sass/helper";

.center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.circle {
  width: 80px;
  height: 80px;

  border-radius: 50%;
  border: 1.2px solid $body-color;

  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.38);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.logo {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: grid;
  img{
    margin: auto;
    padding: 15px;
    place-self: center;
  }
}

@keyframes slide {
  0% {
    transform: translateX(5px)
  }
  50% {
    transform: translateX(-5px)
  }
  100% {
    transform: translateX(5px)
  }
}

.text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 27px;
  letter-spacing: 5px;
  font-weight: normal;
  text-transform: uppercase;
  animation: rotate 14s linear infinite;
  fill: $body-color;
  cursor: default;
}

@keyframes rotate {
  from { transform: rotate(0); }
  to { transform: rotate(360deg); }
}
