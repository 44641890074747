@import "../../assets/sass/helper";

.case-study{

  padding: 60px;

  @include media_1200(max){
    padding: 40px;
  }

  @include media_768(max){
    padding: 40px;
  }


  @include media_480(max){
    padding: 20px;
  }


  .platform-metrics-box{
    padding: 15px;

  }

  .platform{
    color: $heading-color;
    margin-bottom: 8px;

    font-size: 18px;
  }

  .metric-type{
    color: $heading-color;
    font-weight: bold;
    margin-top: 10px;
    font-size: 18px;
    padding-left: 10px;

  }
  .metric-info{
    padding-top: 5px;
    padding-left: 20px;

    div{
      font-size: 16px;
      padding-top: 5px;
    }
  }
  .metric-info {
    display: grid;
    gap: 8px;
  }

  .metric-item {
    display: grid;
    grid-template-columns:  180px auto;
  }

  .metric-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .metric-score{
    color: $heading-color;
    font-weight: bold;
  }

  @include media_480(max){
    ul{
      padding: 0;
    }
      .metric-type{
        margin-top: 20px;
    }

    .metric-item {
      display: flex;
      flex-direction: column;
    }
  }
}
