@import "../../assets/sass/helper";

.dsn-parallax-img {
  position: relative;
  overflow: hidden;
  @include media_991(min) {
    height: var(--height, 640px);
  }

  @include media_575X991 {
    height: var(--height-table,  640px);
  }
  @include media_575() {
    height: var(--height-mobile,  640px);
  }

  &.dsn-animate{
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $bg-color;
      transition: 1s $easeInOutExpo;
    }

    &.dsn-active:after {
      transform: translateX(100%);
      transition-delay: 0.5s;
    }
  }


  img {
    left: 0;
    top: 0;
  }

}
