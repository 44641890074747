
p {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: $line-height-content;
}


cite,
dfn,
em,
i {
  font-style: italic;
}

span, a {
  display: inline-block;
  color: inherit;
  &:hover{
    color: inherit;
  }
}

blockquote {

  cite {
    font-style: normal;
    font-family: $heading-font;
  }
}

pre {
  font-family: $font-pre;
  overflow: auto;
}

code,
kbd,
tt,
var {
  font-family: $font-code;
}

abbr, acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark,
ins {
  background: #fff9c0;
  text-decoration: none;
}

big {
  font-size: 125%;
}

a {
  color: $heading-color !important;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    color: $primary-color !important;
    text-decoration: none;
    transition: all 0.3s ease;
  }

  &:focus {
    text-decoration: underline;
  }
}
