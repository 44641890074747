.main-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

/* Adjust margins and spacing as needed */
.nav-social {
  margin-left: auto;
}


.nav-social {
  margin-left: auto; /* Pushes the social media icons to the right */
}

.nav-social ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav-social {
  display: flex;
  align-items: center;
}

/* Push social media links to the right */
.nav-social ul {
  margin-left: auto;
}

/* Adjust spacing as needed */
.nav-item {
  margin-right: 20px;
}


ul.primary-nav {



  > li {
    font-size: 14px;
    font-weight: 700;
    padding-bottom: 10px;
    padding-top: 10px;

    &:not(:first-of-type) {
      margin-left: 30px;
    }

    &:last-of-type ul {
      margin-left: -95px;
    }


    ul {

      min-width: 200px;
      padding: 10px 20px;
      margin-top: 10px;
      margin-left: -20px;
      background-color: $assistant-color;
      transition-property: transform, opacity, visibility;
      transition-duration: 0.8s;
      border-radius: 7px;
      opacity: 0;
      visibility: hidden;
      transform: translateY(35px);
      transition-timing-function: $easeOutBack;


      li {
        position: relative;
        width: 100%;
        padding: 7px 0;
        font-size: 13px;
        font-weight: 500;

        &:before {
          content: '';
          position: absolute;
          left: -10px;
          top: 0;
          height: 0;
          width: 1.5px;
          transition: 0.5s;
          background-color: $heading-color;
        }

        &:hover:before {
          height: 100%;
        }
      }

      ul {
        right: calc(100% + 15px);
        top: 0;
      }


    }


  }

  li.has-sub-menu {
    &:hover > ul, &:focus > ul {
      opacity: 1;
      transform: none;
      visibility: visible;
    }
  }


}


.navbar-toggle , .bg{
  display: none;
}
.dsn-back{
  display: none;
}
